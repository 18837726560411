import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_SERVICE_PATH = baseConfig.BASE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/role/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_SERVICE_PATH + '/role/detail', { roleId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_SERVICE_PATH + '/role/edit', roleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_SERVICE_PATH + '/role/edit', roleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_SERVICE_PATH + '/role/create', roleData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
